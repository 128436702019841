/*
 * The public theme interface. Use these variables in your components to style
 * them consistently. Our strategy for now is to use Bootstrap's theme as a guide:
 * https://github.com/twbs/bootstrap/blob/v4.6.1/dist/css/bootstrap.css#L7
 * Note: You will need to override bootstrap classes in order to use these variables.
 * See Button.css for a good example.
 */
:root {
  --blue: hsl(var(--3p-color-blue-hs), var(--3p-color-blue-l));
  --primary: var(--blue);
}

/*
 * The private theme interface. Use these to create colors in the main theme. 
 * Use them locally in components if you need to darken or lighten colors. 
*/
:root {
  /*this color and lightness represent #007eb5; which is our 3play Blue*/
  --3p-color-blue-hs: 198, 100%;
  --3p-color-blue-l: 35%;
}
