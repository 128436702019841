.slide-toggle-outer {
  vertical-align: middle;
  box-sizing: content-box;
  width: 30px;
  height: 12px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 12px;
  position: relative;
}

.slide-toggle-outer input[type="checkbox"] {
  visibility: hidden;
  width: 0;
}

.slide-toggle-outer .slide-toggle-knob {
  display: inline-block;
  position: absolute;
  left: 0px;
  width: 12px;
  height: 12px;
  border: #ccc;
  border-radius: 12px;
  background-color: #999;
  transition: left 150ms;
}

.slide-toggle-outer input[type="checkbox"]:checked+.slide-toggle-knob {
  background-color: #007EB5;
  left: 18px;
}
