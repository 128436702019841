.N7ZIPa_betaTerms {
  max-width: 50%;
  margin: 1rem auto;
}

.N7ZIPa_betaTerms .N7ZIPa_termsLink {
  color: #007eb5;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  --3p-primary-dark: hsl(var(--3p-color-blue-hs), calc(var(--3p-color-blue-l)  - 7%));
  --3p-primary-darker: hsl(var(--3p-color-blue-hs), calc(var(--3p-color-blue-l)  - 10%));
  --3p-primary-darkest: hsl(var(--3p-color-blue-hs), calc(var(--3p-color-blue-l)  - 12%));
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
  background-color: var(--3p-primary-dark);
  border-color: var(--3p-primary-darker);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: var(--3p-primary-darker);
  border-color: var(--3p-primary-darkest);
}

:root {
  --blue: hsl(var(--3p-color-blue-hs), var(--3p-color-blue-l));
  --primary: var(--blue);
  --3p-color-blue-hs: 198, 100%;
  --3p-color-blue-l: 35%;
}

.qw_fIq_dropdownItemName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.qw_fIq_dropdownUrl {
  color: #4a525b;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .75rem;
  overflow: hidden;
}

:is(:active .qw_fIq_dropdownUrl, .disabled .qw_fIq_dropdownUrl) {
  color: inherit;
}

.qw_fIq_advancedSettings {
  justify-content: end;
  margin-top: 4px;
  font-size: .75rem;
  display: flex;
}

.qw_fIq_dropdownItem {
  max-width: 400px;
  overflow: hidden;
}

.NSrOeq_blockCheck + .NSrOeq_blockCheck {
  margin-top: 10px;
}

.NSrOeq_labelHeader {
  font-weight: 500;
}

.NSrOeq_helpText {
  font-size: .75rem;
}

.NSrOeq_learnMore {
  text-align: right;
  margin-top: 4px;
  font-size: .75rem;
  display: block;
}

._7S1IGG_checkGrid {
  grid-template-columns: auto 1fr;
  grid-template-areas: "_7S1IGG_icon _7S1IGG_primary"
                       "_7S1IGG_blank _7S1IGG_secondary";
  display: grid;
}

._7S1IGG_checkGrid + ._7S1IGG_checkGrid {
  margin-top: 5px;
}

._7S1IGG_icon {
  grid-area: _7S1IGG_icon;
  align-self: center;
  margin-right: 10px;
}

._7S1IGG_primaryCheck {
  grid-area: _7S1IGG_primary;
  align-self: center;
}

._7S1IGG_secondaryChecks {
  grid-area: _7S1IGG_secondary;
  align-self: center;
}

.gC9WiG_embed {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
}

.gC9WiG_embed textarea[readonly] {
  background: #fdfeff;
  min-height: 10rem;
  font-size: .875rem;
}

.gC9WiG_actions {
  justify-content: space-between;
  align-items: top;
  display: flex;
}

.gC9WiG_overlayArea {
  position: relative;
}

.gC9WiG_overlay {
  z-index: 10;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  padding: var(--padding-lg);
  text-align: center;
  background-color: #ededf6df;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.PLpCva_previewHeader {
  padding: var(--padding-lg);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.PLpCva_share {
  padding: var(--padding-lg);
  z-index: 1;
  background-color: #fff;
  border-top: 1px solid #eee;
  grid-area: PLpCva_share;
  box-shadow: -1px 0 5px #34353d1a;
}

.PLpCva_playerCanvas {
  padding: var(--padding-lg);
  max-height: 100%;
  overflow: scroll;
}

.PLpCva_player {
  transform-origin: top;
  transform: translateZ(0) scale(var(--scale));
  margin: 3rem auto;
  margin-bottom: calc((var(--scale)  - 85%));
  width: max(100%, 300px);
  transition: transform .2s;
}

@media (prefers-reduced-motion) {
  .PLpCva_player {
    transition: none;
  }
}

.PLpCva_canvasScale {
  margin: var(--padding-lg);
  width: calc(50px + 8ch);
  position: absolute;
  top: 0;
  right: 0;
}

.PLpCva_canvasScale input {
  text-align: center;
}

.PLpCva_canvasScale button, .PLpCva_canvasScale button:disabled {
  background-color: #fff;
}

._2qQssa_grid {
  --padding-lg: 24px;
  --padding-sm: 10px;
  grid-template: "_2qQssa_header _2qQssa_header" min-content
                 "_2qQssa_form _2qQssa_preview" 2.5fr
                 "_2qQssa_form _2qQssa_share"
                 / fit-content(40%) 1fr;
  height: 100%;
  display: grid;
}

._2qQssa_nav {
  padding: var(--padding-sm) var(--padding-lg);
  z-index: 1;
  border-bottom: 2px solid #007eb5;
  grid-area: _2qQssa_header;
  justify-content: space-between;
  align-items: center;
  display: flex;
  box-shadow: 0 1px 4px #00000026;
}

nav h1 {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
}

._2qQssa_branding {
  align-items: center;
  display: flex;
}

._2qQssa_branding img {
  height: 1.5rem;
}

._2qQssa_branding > :first-child {
  margin-right: .5rem;
}

._2qQssa_form {
  padding: var(--padding-lg);
  filter: drop-shadow(0 2px 5px #3f3d491a);
  z-index: 2;
  background-color: #fff;
  grid-area: _2qQssa_form;
  min-width: 300px;
  max-width: 500px;
  overflow: auto;
}

._2qQssa_preview {
  background: #fdfeff radial-gradient(#e8e8f1 1px, #0000 0) -19px -19px / 16px 16px;
  grid-area: _2qQssa_preview;
  position: relative;
  overflow: hidden;
}

._2qQssa_preview h2, ._2qQssa_share h2 {
  font-size: 1.25rem;
}

._2qQssa_preview h2 {
  padding: var(--padding-lg);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

._2qQssa_share {
  padding: var(--padding-lg);
  z-index: 1;
  background-color: #fff;
  border-top: 1px solid #eee;
  grid-area: _2qQssa_share;
  box-shadow: -1px 0 5px #34353d1a;
}

._2qQssa_playerCanvas {
  padding: var(--padding-lg);
  max-height: 100%;
  overflow: scroll;
}

._2qQssa_player {
  transform-origin: top;
  transform: translateZ(0) scale(var(--scale));
  margin: 3rem auto;
  margin-bottom: calc((var(--scale)  - 100%) * 1);
  width: max(100%, 300px);
  transition: transform .2s;
}

@media (prefers-reduced-motion) {
  ._2qQssa_player {
    transition: none;
  }
}

._2qQssa_canvasScale {
  margin: var(--padding-lg);
  width: calc(50px + 8ch);
  position: absolute;
  top: 0;
  right: 0;
}

._2qQssa_canvasScale input {
  text-align: center;
}

._2qQssa_canvasScale button {
  background-color: #fff;
}

.rc-steps {
  width: 100%;
  font-size: 0;
  line-height: 1.5;
  display: flex;
}

.rc-steps, .rc-steps * {
  box-sizing: border-box;
}

.rc-steps-item {
  vertical-align: top;
  flex: 1;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.rc-steps-item-container[role="button"] {
  cursor: pointer;
  transition: opacity .3s;
}

.rc-steps-item-container[role="button"]:hover {
  opacity: .7;
}

.rc-steps-item:last-child {
  flex: none;
}

.rc-steps-item:last-child .rc-steps-item-tail, .rc-steps-item:last-child .rc-steps-item-title:after {
  display: none;
}

.rc-steps-item-container {
  display: inline-block;
}

.rc-steps-item-icon, .rc-steps-item-content {
  vertical-align: top;
  display: inline-block;
}

.rc-steps-item-icon {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 26px;
  width: 26px;
  height: 26px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 26px;
  transition: background-color .3s, border-color .3s;
}

.rc-steps-item-icon > .rc-steps-icon {
  color: #108ee9;
  line-height: 1;
  position: relative;
  top: -1px;
}

.rc-steps-item-icon > .rc-steps-icon.rcicon {
  font-size: 12px;
  position: relative;
  top: -2px;
}

.rc-steps-item-tail {
  width: 100%;
  padding: 0 10px;
  position: absolute;
  top: 12px;
  left: 0;
}

.rc-steps-item-tail:after {
  content: "";
  background: #e9e9e9;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  transition: background .3s;
  display: inline-block;
}

.rc-steps-item-content {
  margin-top: 3px;
}

.rc-steps-item-title {
  color: #666;
  margin-bottom: 4px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.rc-steps-item-title:after {
  content: "";
  background: #e9e9e9;
  width: 1000px;
  height: 1px;
  display: block;
  position: absolute;
  top: .55em;
  left: 100%;
}

.rc-steps-item-subtitle {
  color: #999;
  margin-left: 8px;
  font-size: 12px;
  display: inline-block;
}

.rc-steps-item-description {
  color: #999;
  font-size: 12px;
}

.rc-steps-item-wait .rc-steps-item-icon {
  background-color: #fff;
  border-color: #ccc;
}

.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
  color: #ccc;
}

.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #ccc;
}

.rc-steps-item-wait .rc-steps-item-title {
  color: #0000006e;
}

.rc-steps-item-wait .rc-steps-item-title:after {
  background-color: #e9e9e9;
}

.rc-steps-item-wait .rc-steps-item-description {
  color: #0000006e;
}

.rc-steps-item-wait .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}

.rc-steps-item-process .rc-steps-item-icon {
  background-color: #fff;
  border-color: #108ee9;
}

.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #108ee9;
}

.rc-steps-item-process .rc-steps-item-title {
  color: #000000a6;
}

.rc-steps-item-process .rc-steps-item-title:after {
  background-color: #e9e9e9;
}

.rc-steps-item-process .rc-steps-item-description {
  color: #000000a6;
}

.rc-steps-item-process .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: #108ee9;
}

.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #fff;
}

.rc-steps-item-finish .rc-steps-item-icon {
  background-color: #fff;
  border-color: #108ee9;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: #108ee9;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #108ee9;
}

.rc-steps-item-finish .rc-steps-item-title {
  color: #0000006e;
}

.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: #108ee9;
}

.rc-steps-item-finish .rc-steps-item-description {
  color: #0000006e;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: #108ee9;
}

.rc-steps-item-error .rc-steps-item-icon {
  background-color: #fff;
  border-color: #f50;
}

.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon {
  color: #f50;
}

.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #f50;
}

.rc-steps-item-error .rc-steps-item-title {
  color: #f50;
}

.rc-steps-item-error .rc-steps-item-title:after {
  background-color: #e9e9e9;
}

.rc-steps-item-error .rc-steps-item-description {
  color: #f50;
}

.rc-steps-item-error .rc-steps-item-tail:after {
  background-color: #e9e9e9;
}

.rc-steps-item.rc-steps-next-error .rc-steps-item-title:after {
  background: #f50;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
  margin-right: 10px;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item:last-child {
  margin-right: 0;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-tail {
  display: none;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-description {
  max-width: 100px;
}

.rc-steps-item-custom .rc-steps-item-icon {
  background: none;
  border: 0;
  width: auto;
  height: auto;
}

.rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  top: 1px;
}

.rc-steps-item-custom.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #108ee9;
}

.rc-steps-small .rc-steps-item-icon {
  text-align: center;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  font-size: 12px;
  line-height: 18px;
}

.rc-steps-small .rc-steps-item-icon > .rc-steps-icon {
  font-size: 12px;
  font-size: 9px \9 ;
  top: -1px;
  transform: scale(.75);
}

.rc-steps-small .rc-steps-item-content {
  margin-top: 0;
}

.rc-steps-small .rc-steps-item-title {
  color: #666;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
}

.rc-steps-small .rc-steps-item-description {
  color: #999;
  font-size: 12px;
}

.rc-steps-small .rc-steps-item-tail {
  padding: 0 8px;
  top: 8px;
}

.rc-steps-small .rc-steps-item-tail:after {
  border-radius: 1px;
  width: 100%;
  height: 1px;
}

.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}

.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  font-size: 20px;
  top: -2.5px;
  transform: none;
}

.rc-steps-vertical {
  display: block;
}

.rc-steps-vertical .rc-steps-item {
  display: block;
  overflow: visible;
}

.rc-steps-vertical .rc-steps-item-icon {
  float: left;
}

.rc-steps-vertical .rc-steps-item-icon-inner {
  margin-right: 16px;
}

.rc-steps-vertical .rc-steps-item-content {
  min-height: 48px;
  display: block;
  overflow: hidden;
}

.rc-steps-vertical .rc-steps-item-title {
  line-height: 26px;
}

.rc-steps-vertical .rc-steps-item-title:after {
  display: none;
}

.rc-steps-vertical .rc-steps-item-description {
  padding-bottom: 12px;
}

.rc-steps-vertical .rc-steps-item-tail {
  width: 1px;
  height: 100%;
  padding: 30px 0 4px;
  position: absolute;
  top: 0;
  left: 13px;
}

.rc-steps-vertical .rc-steps-item-tail:after {
  width: 1px;
  height: 100%;
}

.rc-steps-vertical.rc-steps-small .rc-steps-item-tail {
  padding: 22px 0 4px;
  position: absolute;
  top: 0;
  left: 9px;
}

.rc-steps-vertical.rc-steps-small .rc-steps-item-title {
  line-height: 18px;
}

.rc-steps-label-vertical .rc-steps-item {
  overflow: visible;
}

.rc-steps-label-vertical .rc-steps-item-tail {
  margin-left: 48px;
  padding: 0 24px;
}

.rc-steps-label-vertical .rc-steps-item-content {
  text-align: center;
  width: 100px;
  margin-top: 8px;
  display: block;
}

.rc-steps-label-vertical .rc-steps-item-icon {
  margin-left: 36px;
  display: inline-block;
}

.rc-steps-label-vertical .rc-steps-item-title {
  padding-right: 0;
}

.rc-steps-label-vertical .rc-steps-item-title:after {
  display: none;
}

.rc-steps-label-vertical .rc-steps-item-description {
  text-align: left;
}

.rc-steps-dot .rc-steps-item-tail {
  width: 100%;
  margin: 0 0 0 50px;
  padding: 0;
  top: 1px;
}

.rc-steps-dot .rc-steps-item-tail:after {
  height: 3px;
}

.rc-steps-dot .rc-steps-item-icon {
  border: 0;
  width: 5px;
  height: 5px;
  margin-left: 48px;
  padding-right: 0;
  line-height: 5px;
}

.rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
  float: left;
  border-radius: 2.5px;
  width: 100%;
  height: 100%;
}

.rc-steps-dot .rc-steps-item-process .rc-steps-dot .rc-steps-item-icon {
  width: 7px;
  height: 7px;
  line-height: 7px;
  top: -1px;
}

.rc-steps-dot .rc-steps-item-process .rc-steps-dot .rc-steps-item-icon .rc-steps-icon-dot {
  border-radius: 3.5px;
}

.rc-steps-navigation {
  padding-top: 8px;
}

.rc-steps-navigation.rc-steps-horizontal .rc-steps-item-description {
  max-width: 140px;
}

.rc-steps-navigation .rc-steps-item {
  box-sizing: border-box;
  text-align: center;
  overflow: visible;
}

.rc-steps-navigation .rc-steps-item-container {
  text-align: left;
  outline: none;
  padding-bottom: 8px;
}

.rc-steps-navigation .rc-steps-item-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
  overflow: hidden;
}

.rc-steps-navigation .rc-steps-item-title:after {
  display: none;
}

.rc-steps-navigation .rc-steps-item:last-child {
  flex: 1;
}

.rc-steps-navigation .rc-steps-item:last-child:after {
  display: none;
}

.rc-steps-navigation .rc-steps-item:after {
  content: "";
  border-top: 1px solid #ccc;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #ccc;
  width: 16px;
  height: 16px;
  margin-top: -12px;
  margin-left: -8px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: rotate(45deg);
}

.rc-steps-navigation .rc-steps-item-active .rc-steps-item-container {
  border-bottom: 3px solid #108ee9;
  padding-bottom: 5px;
}

.card {
  min-width: 250px;
}

.card-img-top {
  width: auto;
}

.disabled {
  color: #999;
  cursor: not-allowed;
}

li.disabled > a {
  color: gray;
  pointer-events: none;
}

.modal-width-small {
  width: 576px;
  max-width: none;
}

.modal-width-medium {
  width: 800px;
  max-width: none;
}

.modal-width-large {
  width: calc(1000px + 6rem);
  max-width: none;
}

.modal-width-x-large {
  width: 80vw;
  max-width: none;
}

.rbt .rbt-input-main::-ms-clear {
  display: none;
}

.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > .dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
}

.rbt-menu > .dropdown-item:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

.rbt-input-multi {
  cursor: text;
  position: relative;
  overflow: hidden;
}

.rbt-input-multi.focus {
  color: #495057;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.rbt-input-multi.form-control {
  height: auto;
}

.rbt-input-multi.form-control[disabled] {
  opacity: 1;
  background-color: #e9ecef;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: -1px;
  margin-bottom: -4px;
  display: flex;
  overflow: hidden;
}

.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

.rbt-close {
  z-index: 1;
}

.rbt-close-lg {
  font-size: 24px;
}

.rbt-token {
  color: #007bff;
  background-color: #e7f4ff;
  border: 0;
  border-radius: .25rem;
  margin: 1px 3px 2px 0;
  padding: 4px 7px;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

.rbt-token-disabled {
  color: #495057;
  pointer-events: none;
  background-color: #0000001a;
}

.rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.rbt-token-active {
  color: #fff;
  background-color: #007bff;
  outline: none;
  text-decoration: none;
}

.rbt-token .rbt-token-remove-button {
  color: inherit;
  font-size: inherit;
  opacity: 1;
  text-shadow: none;
  outline: none;
  padding: 3px 7px;
  font-weight: normal;
  position: absolute;
  top: -2px;
  bottom: 0;
  right: 0;
}

.rbt-aux {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 34px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.rbt-aux-lg {
  width: 46px;
}

.rbt-aux .rbt-close {
  pointer-events: auto;
  margin-top: -4px;
}

.has-aux .rbt-input {
  padding-right: 34px;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  padding: 0;
  font-weight: bold;
}

.input-group > .rbt {
  flex: 1;
}

.input-group > .rbt .rbt-input-hint, .input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  width: 20px;
  height: 20px;
  padding: 0 3px 3px 0;
  position: absolute;
}

.react-resizable-handle-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.react-resizable-handle-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
  transform: rotate(270deg);
}

.react-resizable-handle-w, .react-resizable-handle-e {
  cursor: ew-resize;
  margin-top: -10px;
  top: 50%;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n, .react-resizable-handle-s {
  cursor: ns-resize;
  margin-left: -10px;
  left: 50%;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.custom-handle {
  border: 0 solid #fff;
  width: 16px;
  height: 16px;
  position: absolute;
}

.custom-handle-dark {
  border: 0 solid #000;
  width: 32px;
}

.custom-handle-sw {
  cursor: sw-resize;
  border-width: 0 0 2px 2px;
  bottom: -4px;
  left: -4px;
}

.custom-handle-se {
  cursor: se-resize;
  border-width: 0 2px 2px 0;
  bottom: -4px;
  right: -4px;
}

.custom-handle-nw {
  cursor: nw-resize;
  top: -4px;
  left: -4px;
}

.custom-handle-ne {
  cursor: ne-resize;
  top: -4px;
  right: -4px;
}

.custom-handle-w, .custom-handle-e {
  cursor: ew-resize;
  margin-top: -4px;
  top: 50%;
}

.custom-handle-w {
  left: -4px;
}

.custom-handle-e {
  right: -4px;
}

.custom-handle-n, .custom-handle-s {
  cursor: ns-resize;
  margin-left: -4px;
  left: 50%;
}

.custom-handle-n {
  top: -4px;
}

.custom-handle-s {
  border-width: 0 0 2px;
  bottom: -10px;
}

.display-block {
  display: block;
}

.block-margin:not(:first-child) {
  margin-top: .5em;
}

.inline-margin:not(:first-child) {
  margin-right: .5em;
}

#jobs-bulk-update-form h3 {
  border-bottom: 1px solid #ccc;
  margin-top: .8rem;
  margin-bottom: .3rem;
  font-size: 1rem;
  position: relative;
  top: -.5em;
}

#jobs-bulk-update-form h3 span.text {
  background-color: #fff;
  padding-left: .3em;
  padding-right: .5em;
  position: relative;
  top: .5em;
  left: .5em;
}

.trait-badge {
  background-color: #e0e0e0;
  border-radius: .5em;
  padding: 2px 4px;
}

.input-row > * {
  vertical-align: baseline;
  display: inline;
}

.space-items > :not(:first-child) {
  margin-left: .5em;
}

.update-jobs-message {
  color: #333;
  background-color: #ccc;
  border-radius: .5em;
  margin-bottom: .5em;
  padding: 3px 1em;
  display: flex;
}

.update-jobs-message.error {
  color: #833;
  background-color: #ecc;
}

.update-jobs-message.success {
  color: #383;
  background-color: #cec;
}

.update-jobs-close-banner {
  margin-left: auto;
}

.slide-toggle-outer {
  vertical-align: middle;
  box-sizing: content-box;
  border: 1px solid #ccc;
  border-radius: 12px;
  width: 30px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.slide-toggle-outer input[type="checkbox"] {
  visibility: hidden;
  width: 0;
}

.slide-toggle-outer .slide-toggle-knob {
  background-color: #999;
  border: #ccc;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  transition: left .15s;
  display: inline-block;
  position: absolute;
  left: 0;
}

.slide-toggle-outer input[type="checkbox"]:checked + .slide-toggle-knob {
  background-color: #007eb5;
  left: 18px;
}

/*# sourceMappingURL=application.27f7f7d6.css.map */
