/* Overrides from https://github.com/twbs/bootstrap/blob/v4.6.1/dist/css/bootstrap.css#L2564 */
.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  /* 
   * Vars for darkening button and border colors as described here:
   * https://github.com/twbs/bootstrap/blob/v4.6.1/scss/mixins/_buttons.scss#L6 
   */
  --3p-primary-dark: hsl(var(--3p-color-blue-hs), calc(var(--3p-color-blue-l) - 7%));
  --3p-primary-darker: hsl(var(--3p-color-blue-hs), calc(var(--3p-color-blue-l) - 10%));
  --3p-primary-darkest: hsl(var(--3p-color-blue-hs), calc(var(--3p-color-blue-l) - 12%));
}

.btn-primary:hover {
  background-color: var(--3p-primary-dark);
  border-color: var(--3p-primary-darker);
}

.btn-primary:focus,
.btn-primary.focus {
  background-color: var(--3p-primary-dark);
  border-color: var(--3p-primary-darker);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--3p-primary-darker);
  border-color: var(--3p-primary-darkest);
}
