.display-block {
  display: block;
}

.block-margin:not(:first-child) {
  margin-top: 0.5em;
}

.inline-margin:not(:first-child) {
  margin-right: 0.5em;
}

#jobs-bulk-update-form h3 {
  border-bottom: 1px solid #ccc;
  position: relative;
  top: -0.5em;
  font-size: 1rem;
  margin-top: 0.8rem;
  margin-bottom: 0.3rem;
}

#jobs-bulk-update-form h3 span.text {
  background-color: white;
  padding-left: 0.3em;
  padding-right: 0.5em;
  position: relative;
  top: 0.5em;
  left: 0.5em;
}

.trait-badge {
  background-color: #e0e0e0;
  border-radius: 0.5em;
  padding: 2px 4px;
}

.input-row>* {
  vertical-align: baseline;
  display: inline;
}

.space-items>*:not(:first-child) {
  margin-left: 0.5em;
}

.update-jobs-message {
  border-radius: 0.5em;
  padding: 3px 1em;
  margin-bottom: 0.5em;
  display: flex;
  background-color: #ccc;
  color: #333;
}

.update-jobs-message.error {
  background-color: #ecc;
  color: #833;
}

.update-jobs-message.success {
  background-color: #cec;
  color: #383;
}

.update-jobs-close-banner {
  margin-left: auto;
}
