.custom-handle {
  border: 0 solid white;
  position: absolute;
  height: 16px;
  width: 16px;
}

.custom-handle-dark {
  border: 0 solid black;
  width: 32px;
}

.custom-handle-sw {
  bottom: -4px;
  left: -4px;
  cursor: sw-resize;
  border-width: 0 0 2px 2px;
}

.custom-handle-se {
  bottom: -4px;
  right: -4px;
  cursor: se-resize;
  border-width: 0 2px 2px 0;
}

.custom-handle-nw {
  top: -4px;
  left: -4px;
  cursor: nw-resize;
}

.custom-handle-ne {
  top: -4px;
  right: -4px;
  cursor: ne-resize;
}

.custom-handle-w,
.custom-handle-e {
  top: 50%;
  margin-top: -4px;
  cursor: ew-resize;
}

.custom-handle-w {
  left: -4px;
}

.custom-handle-e {
  right: -4px;
}
.custom-handle-n,
.custom-handle-s {
  left: 50%;
  margin-left: -4px;
  cursor: ns-resize;
}
.custom-handle-n {
  top: -4px;
}
.custom-handle-s {
  bottom: -10px;
  border-width: 0 0 2px 0;
}