/* NOTE: prefer adding to styles.js instead of this file
 * However, if you really want static class names without namespacing, then
 * this file's for you.
 */

.card {
  min-width: 250px;
}

.card-img-top {
  width: auto;
}

.disabled {
  color: #999;
  cursor: not-allowed;
}

li.disabled > a {
  color: gray;
  pointer-events: none;
}

.modal-width-small {
  width: 576px;
  max-width: none;
}

.modal-width-medium {
  width: 800px;
  max-width: none;
}


.modal-width-large {
  width: calc(1000px + 6rem);
  max-width: none;
}

.modal-width-x-large {
  width: 80vw;
  max-width: none;
}
